import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="property-details"
export default class extends Controller {
  // Targets below will nearly always have multiple elements (plural).
  static targets = [
    'button',
    'property', // all
    'propertyNotReleased',
    'propertyForSale',
    'propertyReserved',
    'propertySold',
  ];

  static values = {
    selectedPropertyNumber: Number, // Which property has been selected (i.e. infowindow showing).
    highlightedAvailability: String, // Holds which availability(sold etc) is currently highlighted.
  }

  selectProperty(event) {
    const { propertyNumber } = event.params;
    this.selectedPropertyNumberValue = propertyNumber;
  }

  deSelectProperty(event) {
    const { propertyNumber } = event.params;
    this.selectedPropertyNumberValue = propertyNumber;
  }

  selectedPropertyNumberValueChanged(newPropertyNumber, oldPropertyNumber) {
    if (oldPropertyNumber === undefined) {
      // Only undefined if hasn't yet been assigned (i.e. at very start).
      return;
    }

    this.applyPropertyHighlights();
  }

  applyPropertyHighlights() {
    this.unHighlightAllProperties();
    this.highlightSelectedProperty();
    this.highlightPropertiesOfAvailability();
  }

  unHighlightAllProperties() {
    this.propertyTargets.forEach((propertyTarget) => {
      propertyTarget.classList.remove('highlight');
    });
  }

  highlightSelectedProperty() {
    this.consoleLog('highlighting...', this.selectedPropertyNumberValue);
    if (this.selectedPropertyNumberValue !== 0) {
      this.propertyTargets[this.selectedPropertyNumberValue - 1].classList.add('highlight');
    }
  }

  highlightPropertiesOfAvailability() {
    if (this.highlightedAvailabilityValue !== '') {
      const propertyTargets = this.propertyTargetsForAvailability(
        this.highlightedAvailabilityValue
      );
      propertyTargets.forEach((propertyTarget) => {
        propertyTarget.classList.add('highlight');
      });
    }
  }

  selectAvailability(event) {
    const { availability } = event.params;

    this.buttonTargets.forEach((button) => {
      button.classList.remove('selected');
    });

    if (availability !== this.highlightedAvailabilityValue) {
      this.highlightedAvailabilityValue = availability;
      event.target.classList.add('selected');
    } else {
      // Toggle off, so none selected
      this.highlightedAvailabilityValue = '';
    }
  }

  highlightedAvailabilityValueChanged() {
    this.applyPropertyHighlights();
  }

  propertyTargetsForAvailability(availability) {
    let propertyTargets;
    if (availability === 'not-released') {
      propertyTargets = this.propertyNotReleasedTargets;
    } else if (availability === 'for-sale') {
      propertyTargets = this.propertyForSaleTargets;
    } else if (availability === 'reserved') {
      propertyTargets = this.propertyReservedTargets;
    } else if (availability === 'sold') {
      propertyTargets = this.propertySoldTargets;
    }
    return propertyTargets;
  }

  /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
  consoleLog(...args) {
    // eslint-disable-next-line no-console
    // console.log('propd:   ', ...args); // Uncomment this line to assist debugging/development.
  }
}
