import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="walkthrough-loader"
export default class extends Controller {
  load(event) {
    event.preventDefault();
    this.element.innerHTML = `
      <div
        style="position:relative; height:0; padding-top:56%"
      >
        <iframe
          style="position:absolute; top:0; left:0; width:100%; height:100%;"
          src="${event.target.href}"
          name="Kirkstall Place"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen="true"
          allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
        ></iframe>
      </div>
    `;
  }
}
