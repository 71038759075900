import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = [
    'tab',
    'panel',
  ];

  static values = {
    activeTabNumber: Number,
    activeTabCssClass: { type: String, default: 'btn--selected' },
    locationHashPrefix: { type: String, default: 'section' },
  }

  connect() {
    this.consoleLog('tabs controller connected.');
    this.consoleLog('tabTargets: ', this.tabTargets);
    this.consoleLog('panelTargets: ', this.panelTargets);

    this.prepareForProgressiveEnhancement();

    this.activeTabNumberValue = this.findInitiallyActiveTabNumber();
  }

  switch(event) {
    event.preventDefault();
    const { tabNumber } = event.params;
    this.consoleLog('switch.  To tabNumber:', tabNumber);

    this.activeTabNumberValue = tabNumber;
    this.pushTabNumberToUrl();
  }

  // Stimulus Change Callback:
  activeTabNumberValueChanged(tabNumber, previousTabNumber) {
    if (tabNumber === 0) { return; }

    this.consoleLog(`activeTabNumberValueChanged to be ${tabNumber}, previously ${previousTabNumber}`);

    this.deactivateAllTabsExcept(tabNumber);
    this.deactivateAllPanelsExcept(tabNumber);
    const addFocus = (previousTabNumber !== 0); // At first load leave focus on close.
    this.activateTab(tabNumber, addFocus);
    this.activatePanel(tabNumber);
  }

  prepareForProgressiveEnhancement() {
    // This tabbed interface is a progressive enhancement from the initially-rendered HTML - which
    // should still allow normal tabbing if this javascript is not present or running.
    // Here we add tabindex="-1" to panels - this makes them focusable, but out of reach of normal
    // sequential keyboard navigation (i.e. pressing Tab key).
    this.panelTargets.forEach((panel) => {
      panel.setAttribute('tabindex', '-1');
    });
  }

  deactivateAllTabsExcept(tabNumber) {
    for (let number = 1; number <= this.tabTargets.length; number += 1) {
      if (number !== tabNumber) {
        this.deactivateTab(number);
      }
    }
  }

  deactivateAllPanelsExcept(tabNumber) {
    for (let number = 1; number <= this.tabTargets.length; number += 1) {
      if (number !== tabNumber) {
        this.deactivatePanel(number);
      }
    }
  }

  activateTab(tabNumber, addFocus = true) {
    const tab = this.findTab(tabNumber);
    if (addFocus) {
      tab.focus();
    }
    tab.removeAttribute('tabindex');
    tab.setAttribute('aria-selected', 'true');
    tab.classList.add(this.activeTabCssClassValue);
  }

  deactivateTab(tabNumber) {
    const tab = this.findTab(tabNumber);
    tab.setAttribute('tabindex', '-1');
    tab.removeAttribute('aria-selected');
    tab.classList.remove(this.activeTabCssClassValue);
  }

  activatePanel(panelNumber) {
    const panel = this.findPanel(panelNumber);
    panel.hidden = false;
  }

  deactivatePanel(panelNumber) {
    const panel = this.findPanel(panelNumber);
    panel.hidden = true;
  }

  focusActivePanel() {
    const panel = this.findPanel(this.activeTabNumberValue);
    this.consoleLog('Adding focus to panel', panel);
    panel.focus();
  }

  findTab(tabNumber) {
    return this.tabTargets[tabNumber - 1];
  }

  findPanel(panelNumber) {
    return this.panelTargets[panelNumber - 1];
  }

  findInitiallyActiveTabNumber() {
    if (this.activeTabNumberValue) {
      this.consoleLog('HTML has requested tabNumber be active: ', this.activeTabNumberValue);
      return this.activeTabNumberValue;
    }

    const tabNumberFromUrl = this.pullTabNumberFromUrl();
    if (tabNumberFromUrl) {
      this.consoleLog(`window.location.hash requested tabNumber ${tabNumberFromUrl} be active`);
      return tabNumberFromUrl;
    }

    this.consoleLog('No tab number has been requested to be initially active.  Setting default (1).');
    return 1;
  }

  pushTabNumberToUrl() {
    window.history.pushState(
      null,
      null,
      `#${this.locationHashPrefixValue}${this.activeTabNumberValue}`,
    );
  }

  pullTabNumberFromUrl() {
    const { hash } = window.location;
    const hashTabNumberString = hash.replace(`#${this.locationHashPrefixValue}`, '');
    const hashTabNumber = Number(hashTabNumberString);
    if (hashTabNumber) {
      return hashTabNumber;
    }
    return undefined;
  }

  keydown(event) {
    this.consoleLog('keydown.  event.which: ', event.which);

    const keyCode = event.which;
    const keyName = this.constructor.arrowKeyNameForCode(keyCode);
    if (!keyName || keyName === 'up') { return; }

    if (['down', 'left', 'right'].includes(keyName)) {
      // Only prevent default for these keys, so others (e.g. Esc) may still bubble up elsewhere.
      event.preventDefault();
    }

    if (keyName === 'down') { this.focusActivePanel(); }
    if (keyName === 'left') { this.switchToPreviousTab(); }
    if (keyName === 'right') { this.switchToNextTab(); }
  }

  switchToPreviousTab() {
    if (this.activeTabNumberValue <= 1) { return; }

    this.activeTabNumberValue -= 1;
    this.pushTabNumberToUrl();
  }

  switchToNextTab() {
    if (this.activeTabNumberValue >= this.tabTargets.length) { return; }

    this.activeTabNumberValue += 1;
    this.pushTabNumberToUrl();
  }

  static arrowKeyNameForCode(code) {
    switch (code) {
      case 37:
        return 'left';
      case 38:
        return 'up';
      case 39:
        return 'right';
      case 40:
        return 'down';
      default:
        return undefined;
    }
  }

  /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
  consoleLog(...args) {
    // eslint-disable-next-line no-console
    // console.log('tabs:    ', ...args); // Uncomment this line to assist debugging/development.
  }
}
